import React, { useState, useEffect } from "react";

// import data
import { projectsData } from "./Data";
import { projectsNav } from "./Data";

// import components
import WorkItems from "./WorkItems";

const Projects = () => {
	// const [item, setItem] = useState({ name: "all" });
	// const [active, setActive] = useState(0);
	const [projects, setProjects] = useState([]);

	useEffect(() => {
		setProjects(projectsData);
	}, []);

	// useEffect(() => {
	// 	// get projects based on item
	// 	if (item.name === "all") {
	// 		setProjects(projectsData);
	// 	} else {
	// 		const newProjects = projectsData.filter((project) => {
	// 			return project.category.toLowerCase() === item.name;
	// 		});
	// 		setProjects(newProjects);
	// 	}
	// }, [item]);

	// const handleClick = (e, index) => {
	// 	setItem({ name: e.target.textContent.toLowerCase() });
	// 	setActive(index);
	// };

	return (
		<div>
			{/* projects nav */}
			{/* <div className="work__filters">
				{projectsNav.map((item, index) => {
					return (
						<span
							onClick={(e) => {
								handleClick(e, index);
							}}
							className={`${
								active === index ? "active-work" : ""
							} work__item`}
							key={index}
						>
							{item.name}
						</span>
					);
				})}
			</div> */}
			{/* projects */}
			<div className="work__container container grid">
				{projects.map((item) => {
					return (
						<WorkItems item={item} key={item.id} web={item.web} />
					);
				})}
			</div>
		</div>
	);
};

export default Projects;
