import { useState } from "react";
import "./services.css";

const Services = () => {
	const [toggleState, setToggleState] = useState(0);

	const toggleTab = (index) => {
		setToggleState(index);
	};

	return (
		<section className="services section" id="services">
			{/* ---- TITLE SECTION ---- */}
			<h2 className="section__title">Studies</h2>

			{/* ---- SUBTITLE SECTION ---- */}
			<span className="section__subtitle">Courses</span>

			{/* ---- DIV ALL CONTAINER ---- */}
			<div className="services__container container grid">
				{/* ------------ CONTAINER 1 ---------- */}
				<div className="services__content">
					{/* BOX CONTAINER 1 */}
					<div>
						<i className="bx bxl-python services__icon"></i>
						<h3 className="services__title">
							Developer <br /> Python
						</h3>
					</div>
					{/* BOX BOTON 1 + ONCLICK OPEN */}
					<span
						className="services__button"
						onClick={() => toggleTab(1)}
					>
						See More
						<i className="uil uil-arrow-right services__button-icon"></i>
					</span>
					{/* CONTAINER COLLAPSE 1 + CONDITIONAL */}
					<div
						className={
							toggleState === 1
								? "services__modal active-modal"
								: "services__modal"
						}
					>
						{/* MODAL CONTENT 1 + ONCLICK CLOSE */}
						<div className="services__modal-content">
							<i
								onClick={() => toggleTab(0)}
								className="uil uil-times services__modal-close"
							></i>

							{/* TITLE & DESCRIPTION MODAL */}
							<h3 className="services__modal-title">
								Developer in Python
							</h3>
							<p className="services__modal-description">
								National Technological University. <br /> Level
								initial, intermediate and advanced completed.
							</p>

							{/* LIST MODAL */}
							<ul className="services__modal-services grid">
								<li className="services__modal-service">
									<i className="uil uil-check-circle services__modal-icon"></i>
									<p className="services__modal-info">										
										Introduction/structure, type of data,
										databases, functions, scope of
										variables, control structure and
										loops.
									</p>
								</li>
								<li className="services__modal-service">
									<i className="uil uil-check-circle services__modal-icon"></i>
									<p className="services__modal-info">
										Modules, programming oriented to
										objects, programming, exceptions and
										documentation.
									</p>
								</li>
								<li className="services__modal-service">
									<i className="uil uil-check-circle services__modal-icon"></i>
									<p className="services__modal-info">										
										Manipulation of attributes, decorators,
										metaclasses, development patterns, XML
										and JSON.
									</p>
								</li>
							</ul>
						</div>
					</div>
				</div>

				{/* ------------ CONTAINER 2 ---------- */}
				<div className="services__content">
					{/* BOX CONTAINER 2 */}
					<div>
						<i className="bx bxl-django services__icon"></i>
						<h3 className="services__title">
							Django <br /> Python
						</h3>
					</div>
					{/* BOX BOTON 2 + ONCLICK OPEN*/}
					<span
						onClick={() => toggleTab(2)}
						className="services__button"
					>
						See More
						<i className="uil uil-arrow-right services__button-icon"></i>
					</span>
					{/* CONTAINER COLLAPSE 2 + CONDITIONAL */}
					<div
						className={
							toggleState === 2
								? "services__modal active-modal"
								: "services__modal"
						}
					>
						{/* MODAL CONTENT 2 + ONCLICK CLOSE*/}
						<div className="services__modal-content">
							<i
								onClick={() => toggleTab(0)}
								className="uil uil-times services__modal-close"
							></i>

							{/* TITLE & DESCRIPTION MODAL */}
							<h3 className="services__modal-title">
								Django - Development of scalable applications
							</h3>
							<p className="services__modal-description">
								National Technological University. <br />{" "}
								Site Architecture and Advanced Development.
							</p>

							{/* LIST MODAL */}
							<ul className="services__modal-services grid">
								<li className="services__modal-service">
									<i className="uil uil-check-circle services__modal-icon"></i>
									<p className="services__modal-info">
										MVC pattern, git and frontend development
									</p>
								</li>
								<li className="services__modal-service">
									<i className="uil uil-check-circle services__modal-icon"></i>
									<p className="services__modal-info">
										Frontend development II/III, files
										statics and Embedding jQuery and
										jQuery U.
									</p>
								</li>
								<li className="services__modal-service">
									<i className="uil uil-check-circle services__modal-icon"></i>
									<p className="services__modal-info">
										Backend development and planning,
										works with forms, implementation
										AJAX and Django in production, server
										Apache
									</p>
								</li>
							</ul>
						</div>
					</div>
				</div>

				{/* ------------ CONTAINER 3 ---------- */}
				<div className="services__content">
					{/* BOX CONTAINER 3 */}
					<div>
						<i className="bx bxs-keyboard services__icon"></i>
						<h3 className="services__title">
							Introduction <br /> Python
						</h3>
					</div>
					{/* BOX BOTON 3 + ONCLICK OPEN */}
					<span
						className="services__button"
						onClick={() => toggleTab(3)}
					>
						See More
						<i className="uil uil-arrow-right services__button-icon"></i>
					</span>
					{/* CONTAINER COLLAPSE 3 + CONDITIONAL */}
					<div
						className={
							toggleState === 3
								? "services__modal active-modal"
								: "services__modal"
						}
					>
						{/* MODAL CONTENT 3 + ONCLICK CLOSE */}
						<div className="services__modal-content">
							<i
								onClick={() => toggleTab(0)}
								className="uil uil-times services__modal-close"
							></i>

							{/* TITLE & DESCRIPTION MODAL */}
							<h3 className="services__modal-title">
								Introduction to Python programming.
							</h3>
							<p className="services__modal-description">
								Course completed on Coursera.
							</p>

							{/* LIST MODAL */}
							<ul className="services__modal-services grid">
								<li className="services__modal-service">
									<i className="uil uil-check-circle services__modal-icon"></i>
									<p className="services__modal-info">
									Variables, expressions, conditionals,
									functions.
									</p>
								</li>
								<li className="services__modal-service">
									<i className="uil uil-check-circle services__modal-icon"></i>
									<p className="services__modal-info">							
										Management of files, lists, dictionaries
										and tuples.
									</p>
								</li>
								<li className="services__modal-service">
									<i className="uil uil-check-circle services__modal-icon"></i>
									<p className="services__modal-info">
									Cycles and iteration.
									</p>
								</li>
							</ul>
						</div>
					</div>
				</div>
			</div>
		</section>
	);
};

export default Services;
