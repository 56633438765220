import "./footer.css";

const Footer = () => {
	return (
		<footer className="footer">
			<div className="footer__container container">
				<h1 className="footer__title">JMArroyo</h1>

				{/* FOOTER LIST */}
				<ul className="footer__list">
					<li>
						<a href="#about" className="footer__link">
							About me
						</a>
					</li>

					<li>
						<a href="#portfolio" className="footer__link">
							Proyects
						</a>
					</li>

					<li>
						<a href="#services" className="footer__link">
							Studies
						</a>
					</li>
				</ul>
				{/* FOOTER SOCIALS */}
				<div className="footer__social">
					<a
						href="https://www.linkedin.com/in/juanmarroyo/"
						className="footer__social-link"
						target="_blank"
					>
						<i className="bx bxl-linkedin"></i>
					</a>
					<a
						href="https://www.instagram.com"
						className="footer__social-link"
						target="_blank"
					>
						<i className="bx bxl-instagram"></i>
					</a>
					<a
						href="https://github.com/juan-arroyo"
						className="footer__social-link"
						target="_blank"
					>
						<i className="bx bxl-github"></i>
					</a>
				</div>
				<span className="footer__copy">
					&#169; JM Arroyo. All rights reserved.
				</span>
			</div>
		</footer>
	);
};

export default Footer;
