const Frontend = () => {
	return (
		<div className="skills__content">
			<h3 className="skills__title">Frontend Developer</h3>

			<div className="skills__box">
				{/* SKILLS GROUP 1 */}
				<div className="skills__group">
					{/* SKILL DATA 1 */}
					<div className="skills__data">
						<i className="bx bx-badge-check"></i>
						<div>
							<h3 className="skills__name">HTML</h3>
							<br />
						</div>
					</div>
					{/* SKILL DATA 2 */}
					<div className="skills__data">
						<i className="bx bx-badge-check"></i>
						<div>
							<h3 className="skills__name">CSS</h3>
							<br />
						</div>
					</div>
					{/* SKILL DATA 3 */}
					
				</div>
				{/* SKILLS GROUP 2 */}
				<div className="skills__group">
					{/* SKILL DATA 1 */}
					<div className="skills__data">
						<i className="bx bx-badge-check"></i>
						<div>
							<h3 className="skills__name">Bootstrap</h3>
							<br />
						</div>
					</div>
					{/* SKILL DATA 2 */}
					<div className="skills__data">
						<i className="bx bx-badge-check"></i>
						<div>
							<h3 className="skills__name">React</h3>
							<br />
						</div>
					</div>
					{/* SKILL DATA 3 */}
					
				</div>
			</div>
		</div>
	);
};

export default Frontend;
