const Info = () => {
	return (
		<div className="about__info grid">
			<div className="about__box">
				<i className="uil uil-10-plus about__icon"></i>
				<h3 className="about__title">Discipline</h3>
				{/* <span className="about__subtitle">8 Years Working</span> */}
			</div>

			<div className="about__box">
				<i className="uil uil-users-alt about__icon"></i>
				<h3 className="about__title">Commitment</h3>
				{/* <span className="about__subtitle">48 + Projects</span> */}
			</div>

			<div className="about__box">
				<i className="uil uil-user-plus about__icon"></i>
				<h3 className="about__title">Proactivity</h3>
				{/* <span className="about__subtitle">Online 24/7</span> */}
			</div>
		</div>
	);
};

export default Info;
