import rick from "../../assets/rick.jpg";
import quintas from "../../assets/quintas.JPG";
import django from "../../assets/django.jpg";

export const projectsData = [
	{
		id: 1,
		image: rick,
		title: "Rick & Morty Api",
		category: "web",
		web: "https://rick-and-morty.jmarroyo.es/",
	},
	{
		id: 2,
		image: django,
		title: "Django Crud Auth",
		category: "app",
		web: "https://github.com/juan-arroyo/djangoCrud",
	},{
		id: 3,
		image: quintas,
		title: "Quintas Rosario",
		category: "app",
		web: "https://quintasrosario.com",
		
	},
];

// projects
export const projectsNav = [
	{
		name: "all",
	},
	{
		name: "web",
	},
	{
		name: "app",
	},
	{
		name: "design",
	},
];
