const Backend = () => {
	return (
		<div className="skills__content">
			<h3 className="skills__title">Backend Developer</h3>

			<div className="skills__box">
				{/* SKILLS GROUP 1 */}
				<div className="skills__group">
					{/* SKILL DATA  */}
					<div className="skills__data">
						<i className="bx bx-badge-check"></i>
						<div>
							<h3 className="skills__name">Python</h3>
							<br />
						</div>
					</div>
					{/* SKILL DATA  */}
					<div className="skills__data">
						<i className="bx bx-badge-check"></i>
						<div>
							<h3 className="skills__name">Django</h3>
							<br />
						</div>
					</div>
					{/* SKILL DATA  */}
					<div className="skills__data">
						<i className="bx bx-badge-check"></i>
						<div>
							<h3 className="skills__name">Git GitHub</h3>
							<br />
						</div>
					</div>
				</div>
				{/* SKILLS GROUP 2 */}
				<div className="skills__group">
					{/* SKILL DATA  */}
					<div className="skills__data">
						<i className="bx bx-badge-check"></i>
						<div>
							<h3 className="skills__name">JavaScript</h3>
							<br />
						</div>
					</div>
					<div className="skills__data">
						<i className="bx bx-badge-check"></i>
						<div>
							<h3 className="skills__name">Node Js</h3>
							<br />
						</div>
					</div>
					<div className="skills__data">
						<i className="bx bx-badge-check"></i>
						<div>
							<h3 className="skills__name">SQL</h3>
							<br />
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Backend;
