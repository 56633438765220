const Social = () => {
	return (
		<div className="home__social">
			<a
				href="https://www.instagram.com/arroyojuann/"
				className="home__social-icon"
				target="_blank"
			>
				<i className="uil uil-instagram"></i>
			</a>
			<a
				href="https://www.linkedin.com/in/juanmarroyo/"
				className="home__social-icon"
				target="_blank"
			>
				<i className='bx bxl-linkedin'></i>
			</a>
			<a
				href="https://github.com/juan-arroyo"
				className="home__social-icon"
				target="_blank"
			>
				<i className="uil uil-github-alt"></i>
			</a>
		</div>
	);
};

export default Social;
