import React from "react";
import "./about.css";

const About_complete = () => {
	return (
		<p className="about_complete">
			Como resultado de la constante inestabilidad en mi país, siendo
			padre de familia (esposa e hijo de casi 4 años), me vi en la
			obligación de buscar nuestro futuro en otro sitio, actualmente me
			encuentro viviendo en Alicante, poseo DNI español y todos mis
			papeles en regla. Soy una persona dedicada, con fácil adaptabilidad
			a los cambios, muy capaz y predispuesto a afrontar los nuevos
			desafíos que se avecinan. La programación es lo que me apasiona,
			tengo conocimientos que fuí reforzando en el correr de los años con
			constantes capacitaciones. A su vez, poseo más de 10 años
			experiencia en el sector informático / electrónico (tanto en soporte
			técnico al usuario como en ventas). Mi principal objetivo es poder
			brindarle a mi familia (los espero en diciembre) la sustentabilidad
			que merecen. <br /> Conocerme no le llevará mas de 10 minutos y le aseguro
			que será un momento grato. Desde ya agradezco la oportunidad.
		</p>
	);
};

export default About_complete;
